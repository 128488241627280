import { Injectable } from '@angular/core';
import { forkJoin, map, tap } from 'rxjs';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { Event } from '../models/event/event.model';
import { Ticket } from '../models/event/ticket.model';
import { Profile } from '../models/user/profile.model';
import { EventService } from './event.service';
import { TicketsService } from './tickets.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutApproverService {
  event: Event;
  profile: Profile;
  formId: string;
  tickets: Ticket[] = [];

  constructor(
    private profileService: ProfileService,
    private ticketsService: TicketsService,
    private eventService: EventService
  ) { }

  loadData(profileId: string, formId: string, eventId: string) {
    this.formId = formId;
    return forkJoin(
      {
        profile: this.getProfile(profileId, eventId),
        tickets: this.getTicketsForm(formId),
        event: this.getEvent(eventId)
      })
      .pipe(
        tap(({ profile, tickets, event }) => {
          this.profile = profile;
          this.tickets = tickets;
          this.event = event;

        }),
        map(({ profile, tickets, event }) => {
          return {
            profile: profile,
            tickets: tickets,
            event: event,
          }
        }
        )
      );
  }

  getProfile(profileId: string, eventId: string) {
    return this.profileService.getProfileForPayment(profileId, eventId);
  }

  getTicketsForm(formId: string) {
    return this.ticketsService.getTicketsOfForm(formId);
  }

  getEvent(eventId: string) {
    return this.eventService.getEventById(eventId);
  }

}
