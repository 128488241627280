import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  publicUrls: string[] = ['event-registration', 'public', 'uploads', 'countries', 'timezones', 'industries','workshop-sessions', 'registration-form', 'ticket', 'by-profile-id']

  constructor(
    private oauthService: OAuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.oauthService.getAccessToken();
    if (this.isPublic(request.url)) {
      return next.handle(request);
    }

    if (request.body instanceof File) {
      return next.handle(request);
    }

    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return next.handle(clonedRequest);
  }

  isPublic(url: string){
    let check = false;
    this.publicUrls.map(pUrl => {
      if(url.includes(pUrl)){
        check = true;
      }
    });
    return check;
  }
}
