import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Subject, debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from "rxjs";
import { CacheService } from "src/app/dynamic-form/services/cache.service";
import { ItemSelect } from "../../models/select-item.model";

@Component({
  selector: 'formly-industry-input',
  templateUrl: './formly-industry-input.component.html',
  styleUrls: ['./formly-industry-input.component.scss']
})
export class FormlyIndustryInputComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  showDropdown: boolean = false;
  selected: ItemSelect = { id: '', label: '', subElements: [] };
  options$;

  constructor(
    private CacheService: CacheService
  ) {
    super();
  }

  ngOnInit() {
    if (this.CacheService.getCache('industry')) {
      this.select(this.CacheService.getCache('industry'));
      this.search({ target: { value: this.selected.label } });
    } else if (this.formControl.value) {
      this.select(this.formControl.value);
    }
    this.close();

    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(this.to.search$),
    );
    this.options$.subscribe();
  }

  search($event) {
    this.open();
    this.search$.emit($event.target.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

  open() {
    this.showDropdown = true;
  }

  close() {
    this.showDropdown = false;
  }

  select(option: ItemSelect) {
    if (this.selected.id == option.id) {
      this.selected = { id: '', label: '', subElements: [] };
      this.formControl.setValue('');
      this.CacheService.removeCache('industry');
    } else {
      this.selected = option;
      this.formControl.setValue(option.id);
      this.CacheService.setCache('industry', this.selected);
      this.close();
    }
  }

  isSelected() {
    return this.selected.id != '';
  }
}
