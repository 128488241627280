import { Component, OnInit } from '@angular/core';
import { Event } from '../shared/models/event/event.model';
import { CheckoutApproverService } from '../shared/services/checkout-approver.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  event: Event;

  constructor(private checkoutService: CheckoutApproverService) { }

  ngOnInit(): void {
    this.event = this.checkoutService.event;
  }

}
