import { Injectable } from '@angular/core';
import { differenceInMinutes, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class WaveGatewayService {

  constructor() { }


  getGatewayUrl(details: any) {
    return details.wave_launch_url;
  }

  getExpiresInMin(details: any) {
    const createdAt: string = details.when_created;
    const expiresIn: string = details.when_expires;


    return differenceInMinutes(parseISO(expiresIn), parseISO(createdAt));
  }

}
