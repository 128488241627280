import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { SchemaBuilderHelper } from 'src/app/dynamic-form/services/schema-builder-helper.service';
import { RegisterRequest } from '../models/profile/regiter.request';
import { Profile } from '../models/user/profile.model';
import { AppSettingService } from './app-setting.service';
import { CountryService } from './country.service';
import { ErrorService } from './error.service';
import { EventService } from './event.service';
import { IndustryService } from './industry.service';
import { WantedDealsService } from './wanted-deals.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  defaultField: string[] = ['country', 'myIndustry', 'myOffer', 'myInterests', 'lookingFor', 'salutation', 'name', 'email', 'mobilePhone', 'businessCity', 'address', 'company', 'occupation', 'workshopIds'];

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private eventService: EventService,
    private errorService: ErrorService,
    private industryService: IndustryService,
    private wantedDealService: WantedDealsService,
    private countryService: CountryService,
    private schemaBuilderHelper: SchemaBuilderHelper,
  ) { }


  registerToEvent(request: RegisterRequest) {
    const url: string = `${this.appSettings.settings.url}/event-registration/new`;
    const currentEventId: string = this.eventService.event.id;
    request.eventId = currentEventId;

    const api$ = this.httpClient.post<Profile>(url, request);

    return api$.pipe(
      catchError(error => this.errorService.handleError(error, null, true)),
    );
  }

  buildRequestFromFormModel(values: any): RegisterRequest {
    const country = this.countryService.getById(values.country);
    const industry = this.industryService.getById(values.myIndustry);
    const offer = values.myOffer.map(id => this.industryService.getById(id));
    const industries = values.myInterests.map(id => this.industryService.getById(id));
    const wantedDeals = values.lookingFor ? values.lookingFor.map(id => this.wantedDealService.getById(id)) : [];

    const request: RegisterRequest = new RegisterRequest(
      '',
      values.salutation,
      values.firstName,
      values.lastName,
      values.email,
      values.mobilePhone,
      country,
      values.businessCity,
      values.address,
      values.company,
      values.occupation,
      industry,
      offer,
      industries,
      wantedDeals || [],
      values.workshopIds,
      this.eventService.event.id,
      {}
    );

    const valuesKeys = Object.keys(values);
    let metaField = {};
    valuesKeys.forEach(key => {
      if (!this.schemaBuilderHelper.isDefaultField(key)) {
        metaField[key] = values[key];
      }
    });
    console.log(metaField);
    request.metaField = metaField;
    console.log(request);

    return request;
  }


}
