import { Component } from '@angular/core';

@Component({
  selector: 'checkout-questions-section',
  templateUrl: './checkout-questions-section.component.html',
  styleUrl: './checkout-questions-section.component.scss'
})
export class CheckoutQuestionsSectionComponent {

}
