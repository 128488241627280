<div class="bg-white">
  <checkout-header></checkout-header>
  <main>
    <router-outlet></router-outlet>


    <!-- Testimonial section -->
    <div class="mx-auto mt-28 max-w-7xl sm:mt-28 sm:px-6 lg:px-8">
      <div
        class="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
        <img class="absolute inset-0 h-full w-full object-cover" [src]="event.theme.banner.name | image | async" alt="">

        <div class="absolute inset-0  bg-gradient-to-tr from-gray-900 via-gray-900/40"></div>
        <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

        <div class="relative mx-auto max-w-2xl lg:mx-0">
          <p
            class="font-text mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8 text-truncate overflow-ellipsis line-clamp-4">
            {{ event.description }}
          </p>
        </div>
      </div>
    </div>

    <checkout-questions-section></checkout-questions-section>
  </main>
</div>

<checkout-footer></checkout-footer>
