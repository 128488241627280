import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { Profile, UpdatePasswordRequest } from '../models/user/profile.model';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  constructor(
    private appSetting: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) { }

  qrCodeUrl(eventId: string) {
    const url: string = `${this.appSetting.settings.url}/profile/profile-qrcode?eventId=${eventId}`;
    return url;
  }

  getUserProfile(eventId: string) {
    const url: string = `${this.appSetting.settings.url}/profile/event-profile?eventId=${eventId}`;
    const api = this.httpClient.get<Profile>(url);
    return api.pipe(
      catchError(error => this.errorService.handleError(error, { title: 'Event Profile', message: 'We could not load your event profile.' }, true))
    );
  }

  getProfileForPayment(profileId: string, eventId: string) {
    const url: string = `${this.appSetting.settings.url}/public/profiles/v1/profile-for-payment?profileId=${profileId}&eventId=${eventId}`;
    const api = this.httpClient.get<Profile>(url);
    return api.pipe(
      catchError(error => this.errorService.handleError(error, { title: 'Event Profile', message: 'We could not load your event profile.' }, true))
    );
  }

  updatePassword(request: UpdatePasswordRequest) {
    const url: string = `${this.appSetting.settings.url}/user/update-password`;
    const api = this.httpClient.patch(url, request);
    return api.pipe(
      catchError(error => this.errorService.handleError(error, { title: 'User Profile', message: 'We could not update your password.' }, true))
    );
  }
}
