import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/models/event/event.model';
import { Profile } from 'src/app/shared/models/user/profile.model';
import { CheckoutApproverService } from 'src/app/shared/services/checkout-approver.service';

@Component({
  selector: 'checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrl: './checkout-header.component.scss'
})
export class CheckoutHeaderComponent implements OnInit {
  event: Event;
  profile: Profile;

  constructor(private checkoutService: CheckoutApproverService) { }

  ngOnInit(): void {
    this.event = this.checkoutService.event;
    this.profile = this.checkoutService.profile;
  }

}
