import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutApproverService } from '../shared/services/checkout-approver.service';

@Injectable({ providedIn: 'any' })
export class CheckoutApproverResolver implements Resolve<any> {

  constructor(private checkoutApproverService: CheckoutApproverService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const profileId = route.paramMap.get('profileId');
    const formId = route.paramMap.get('formId');
    const eventId = route.paramMap.get('eventId');


    return this.checkoutApproverService.loadData(profileId, formId, eventId);
  }

}
