import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SchemaBuilderService } from 'src/app/dynamic-form/services/schema-builder.service';
import { SchemaSkeletonService } from 'src/app/dynamic-form/services/schema-skeleton.service';
import { Event } from 'src/app/shared/models/event/event.model';
import { Ticket } from 'src/app/shared/models/event/ticket.model';
import { RegisterRequest } from 'src/app/shared/models/profile/regiter.request';
import { Profile } from 'src/app/shared/models/user/profile.model';
import { EventService } from 'src/app/shared/services/event.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { TicketsService } from 'src/app/shared/services/tickets.service';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
    trigger('translate', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
  ]
})
export class RegistrationPageComponent implements OnInit {
  event: Event;
  form: Form;
  formId: string;
  selectedForm;
  formDefinition: FormGroup = new FormGroup({});
  saving: boolean = false;
  savedWithError: boolean = false;
  savedWithSuccess: boolean = false;
  tickets: Ticket[] = [];
  loaded: boolean = false;


  model;
  fields: FormlyFieldConfig[];
  isModal = false;
  isModalDetails = false;
  ready: boolean = false;

  constructor(
    private schemaSkeletonService: SchemaSkeletonService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private schemaBuilderService: SchemaBuilderService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService: TicketsService
  ) { }

  ngOnInit(): void {
    this.event = this.eventService.event;
    this.formId = this.route.snapshot.paramMap.get('formId');
    this.setUpForm(this.formId);
    this.loadTickets();
  }

  setUpForm(formId: string) {
    this.ready = false;
    this.schemaSkeletonService
      .loadForms(this.event.id)
      .subscribe({
        next: forms => {
          const index = forms.findIndex(form => form.id == formId);
          this.selectedForm = forms[index];
          this.schemaBuilderService.build(forms[index].formDefinition.schema);
          this.formDefinition = new FormGroup({});
          this.fields = this.schemaBuilderService.getFormFields();
          this.model = this.schemaBuilderService.getModel();
          this.ready = true;
        },
        error: error => {
          this.ready = false;
        }
      });

    this.loaded = true;
  }

  onSubmit() {
    if (!this.formDefinition.valid) {
      return;
    }

    this.savedWithError = false;
    this.savedWithSuccess = false;
    this.saving = true;
    this.isModal = true;
    const values = this.formDefinition.getRawValue();
    const request: RegisterRequest = this.registrationService.buildRequestFromFormModel(values);

    this.registrationService
      .registerToEvent(request)
      .subscribe({
        next: (result) => {
          console.log(result);
          this.saving = false;
          this.redirectToPayment(result);
        },
        error: (error) => {
          console.log(error);
          this.savedWithError = true;
          this.saving = false;
        },
      });
  }

  closeModal($event) {
    this.savedWithError = false;
    this.isModal = false;
  }

  redirectToPayment(profile: Profile) {
    console.log(this.tickets);
    if (this.tickets.length == 0) {
      this.savedWithSuccess = true;
      return;
    };

    const profileId = profile.id;
    const eventId = this.event.id;
    const formId = this.formId;

    this.router.navigate(['/checkout', eventId, profileId, formId]);
  }

  loadTickets() {
    this.ticketsService.getTicketsOfForm(this.formId).subscribe({
      next: (tickets) => {
        this.tickets = tickets;
        console.log(tickets);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  toggleDetails() {
    this.isModalDetails = !this.isModalDetails;
  }

  isEventOneDay() {
    return this.eventService.isEventOneDay();
  }
}
