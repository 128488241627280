import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ticket } from '../models/event/ticket.model';
import { AppSettingService } from './app-setting.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) { }

  public getTicketsOfForm(formId: string){
    const url: string = `${this.appSettings.settings.url}/ticket/of/${formId}`;
    const api = this.httpClient.get<Ticket[]>(url);
    return api.pipe();
  }


}
