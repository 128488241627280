<div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
  <div class="mx-auto max-w-4xl text-center">
    <h1 class="text-base font-semibold leading-7 text-indigo-600">Ticketing</h1>
    <div class="mt-2 flex justify-center items-center gap-x-3">
      <img *ngIf="profile?.user?.profilePhoto?.name?.length > 0" class="rounded-md ring-4 ring-white bg-white sm:h-14 sm:w-14"
        [src]="event?.theme?.logo?.name | image | async" alt="">
      <p class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{ event.name }}</p>
    </div>
  </div>
  <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Choose an affordable plan that’s packed
    with the best features for engaging your audience, creating customer loyalty, and driving sales.</p>
  <div
    class="isolate mx-auto mt-20 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-3xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
    @for (ticket of tickets; track ticket.id) {
    <div (click)="onSelect(ticket)" [ngClass]="{'ring-2 ring-main': selected?.id == ticket.id }"
      class="rounded-3xl p-8 ring-1 ring-gray-200">
      <h2 [id]="ticket.id" [ngClass]="{'text-main': selected?.id == ticket.id }"
        class="text-lg font-semibold leading-8 text-gray-900">{{ ticket.name }}</h2>
      <p class="mt-4 text-sm leading-6 text-gray-600">{{ removeHtml(ticket.description) }}</p>
      <p class="mt-6 flex items-baseline gap-x-1">
        <span class="text-4xl font-bold tracking-tight text-gray-900">{{ getMetaData(ticket, 'currency') }}{{
          ticket.regular_price }}</span>
      </p>
      <a (click)="buyPlan(ticket)" aria-describedby="{{ ticket.id }}"
        [ngClass]="{'bg-main text-white shadow-sm': selected?.id == ticket.id }"
        class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main text-main ring-1 ring-inset ring-main/55 hover:ring-main/60 hover:cursor-pointer hover:bg-main hover:text-white">Buy
        plan</a>
      <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
        @for (include of getMetaData(ticket, 'includes'); track $index) {
        <li class="flex gap-x-3">
          <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clip-rule="evenodd" />
          </svg>
          {{ include }}
        </li>
        }
      </ul>
    </div>
    }
  </div>
</div>

<div *ngIf='isModal' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @AnimationTrigger0 *ngIf='isModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true">
  </div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0">
      <div @AnimationTrigger1 *ngIf='isModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:inset-y-1 sm:w-full sm:max-w-fit">
        <div>
          @if(isloading){
          <div class="mx-auto flex w-full items-center justify-center rounded-full">
            <svg class="animate-spin h-6 w-6 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
          </div>
          }
          @if(!isloading){
          <div class="flex justify-between items-start gap-x-10 pb-2">
            <p class="text-slate-700 font-medium text-sm">Payment expires in {{ expiresIn }} minutes</p>
            <button (click)="toggleModal()" type="button"
              class="rounded-full bg-slate-600 p-1.5 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          }


          @if (!isloading ) {
          <!-- Product -->
          <div class="bg-white">
            <div class="mx-auto max-w-full lg:grid lg:grid-cols-3 lg:gap-x-8">
              <div class="flex flex-col items-stretch justify-around lg:max-w-lg">
                <div>
                  <div>
                    <img class="h-32 w-full rounded-lg object-cover lg:h-32" [src]="event.theme.banner.name | image | async" alt="">
                  </div>
                  <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                      <div class="flex">
                        <img class="h-20 w-20 rounded-lg ring-4 ring-white bg-white"
                          [src]="event?.theme?.logo?.name | image | async" alt="">
                      </div>
                      <div class="mt-10 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div class="mt-10 min-w-0 flex-1 sm:hidden md:block">
                          <h1 class="truncate text-2xl font-bold text-gray-900">{{ event.name }}</h1>
                        </div>
                      </div>
                    </div>
                    <div class="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
                      <h1 class="truncate text-2xl font-bold text-gray-900">{{ event.name }}</h1>
                    </div>
                  </div>
                </div>

                <div class="p-3.5 mt-10 border rounded-lg border-slate-200">
                  <div class="">
                    <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ selected.name }}</h1>
                  </div>

                  <section aria-labelledby="information-heading" class="mt-4">
                    <h2 id="information-heading" class="sr-only">Ticket information</h2>

                    <div class="flex items-center">
                      <p class="text-lg text-gray-900 sm:text-xl">{{ getMetaData(selected, 'currency') }}{{
                        selected.regular_price }}</p>
                    </div>

                    <div class="mt-4 space-y-6">
                      <p class="text-base text-gray-500">{{ removeHtml(selected.description) }}</p>
                    </div>

                    <div class="mt-6 grid grid-cols-1 gap-2">
                      @for (include of getMetaData(selected, 'includes'); track $index) {
                      <div class="overflow-hidden text-truncate text-ellipsis flex">
                        <svg class="h-5 w-5 flex-shrink-0 text-blue-500" viewBox="0 0 20 20" fill="currentColor"
                          aria-hidden="true">
                          <path fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd" />
                        </svg>
                        <p class="ml-2 text-sm text-gray-500">{{ include }}</p>
                      </div>
                      }
                    </div>
                  </section>
                </div>

                <div class="mt-10 md:mt-24">
                  <button (click)="toggleModal()" type="button" class="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3.5 py-2 text-sm font-semibold text-slate-700 ring-1 ring-inset ring-white hover:bg-gray-50">
                    <svg class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                    </svg>
                    Back
                  </button>
                </div>
              </div>

              <div class="mt-10 col-span-2 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                <div class="rounded-lg">
                  <iframe class="rounded-md" [src]="gatewayUrl" frameborder="0" width="100%" height="790px"></iframe>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>

<checkout-sponsors></checkout-sponsors>
