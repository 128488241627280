
  <!-- Footer -->
  <footer class="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <img class="h-7" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Company name">
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Solutions</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Marketing</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Analytics</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Commerce</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Insights</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Support</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Pricing</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Documentation</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Guides</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">API Status</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">About</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Blog</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Jobs</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Press</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Partners</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Claim</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Privacy</a>
                </li>

                <li>
                  <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Terms</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
