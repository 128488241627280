import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'welcome';
  originalImagePath: string = "../../../assets/logo/gray-logo.png";
  hoverImagePath: string = "../../../assets/logo/logo.png";

  currentLanguage = 'en';
  openLanguage: boolean = false;
  defaultColor: boolean = true;

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    const storedLang = localStorage.getItem('language');
    const defaultLang = storedLang || 'en';
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang);
    localStorage.setItem('language', defaultLang);
    this.currentLanguage = defaultLang;
  }

  changeImage(img: HTMLImageElement) {
    img.src = this.hoverImagePath;
  }

  resetImage(img: HTMLImageElement) {
    img.src = this.originalImagePath;
  }

  openLanguageModal() {
    this.openLanguage = true;
  }

  closeLanguageModal() {
    this.openLanguage = false;
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.currentLanguage = language;
    this.openLanguage = false;
  }
}
