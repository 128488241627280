import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { Event } from '../models/event/event.model';
import { EventNature } from '../models/shared/event-nature.enum';
import { AppSettingService } from './app-setting.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  eventSub: BehaviorSubject<Event> = new BehaviorSubject(null);
  event: Event;

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) { }


  getEventById(eventId: string) {
    const url: string = `${this.appSettings.settings.url}/public/events/v1/${eventId}`;
    const api$ = this.httpClient.get<Event>(url);

    return api$.pipe(
      tap(result => this.event = result),
      tap(result => this.eventSub.next(result))
    );
  }

  location(event: Event) {
    if (event.nature == EventNature.Virtual) return "This event is happening online."
    return `${event.location.country.name}, ${event.location.state}, ${event.location.city}, ${event.location.address, event.location.postcode}`;
  }

  downloadBrochure(url: string, originalName: string) {
    return this.httpClient.get(url, {
      responseType: 'blob'
    }).subscribe(blob => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = originalName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    })
  }

  isEventOneDay() {
    const start: string = this.event.schedule.from.split('T')[0];
    const end: string = this.event.schedule.to.split('T')[0];
    return start == end;
  }
}
