import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { checkoutApproverGuard } from './checkout-approver.guard';
import { CheckoutApproverResolver } from './checkout-approver.resolver';
import { CheckoutPricingSectionComponent } from './checkout-pricing-section/checkout-pricing-section.component';
import { CheckoutComponent } from './checkout.component';

// eventId:667be080dd7585477aeef74a/profileId: 6699190cab6328376220011d/formId: 6699029aab6328376220011c
const routes: Routes = [
  {
    path: 'checkout/:eventId/:profileId/:formId',
    component: CheckoutComponent,
    canActivate: [checkoutApproverGuard],
    resolve: [CheckoutApproverResolver],
    children: [
      { path: '', redirectTo: 'pricing', pathMatch: 'full' },
      { path: 'pricing', component: CheckoutPricingSectionComponent, title: 'Pricing' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule { }
