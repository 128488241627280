import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Event } from 'src/app/shared/models/event/event.model';
import { Ticket } from 'src/app/shared/models/event/ticket.model';
import { Profile } from 'src/app/shared/models/user/profile.model';
import { CheckoutApproverService } from 'src/app/shared/services/checkout-approver.service';
import { TicketingPurchaseService } from 'src/app/shared/services/ticketing-purchase.service';
import { WaveGatewayService } from './../../shared/services/wave-gateway.service';

@Component({
  selector: 'checkout-pricing-section',
  templateUrl: './checkout-pricing-section.component.html',
  styleUrl: './checkout-pricing-section.component.scss',
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class CheckoutPricingSectionComponent implements OnInit {
  profile: Profile;
  event: Event;
  tickets: Ticket[] = [];
  selected: Ticket;
  isloading: boolean = false;

  gatewayDetails: any;
  intervalId;
  gatewayUrl;
  expiresIn: number;
  isModal = false;


  constructor(
    private checkoutService: CheckoutApproverService,
    private ticketPurchaseService: TicketingPurchaseService,
    private sanitizer: DomSanitizer,
    private waveGatewayService: WaveGatewayService) { }

  ngOnInit(): void {
    this.event = this.checkoutService.event;
    this.profile = this.checkoutService.profile;
    this.tickets = this.checkoutService.tickets;
  }

  removeHtml(paragraph: string) {
    return paragraph.replace(/<[^>]*>/g, '');
  }

  getMetaData(ticket: Ticket, key: string) {
    return ticket.meta_data.find(e => e.key == key).value;
  }

  onSelect(ticket: Ticket) {
    this.selected = ticket;
  }

  buyPlan(ticket: Ticket) {
    this.reset();
    this.isloading = true;
    this.onSelect(ticket);
    this.toggleModal();


    const profileId: string = this.checkoutService.profile.id;
    const formId: string = this.checkoutService.formId;

    this.ticketPurchaseService
      .startPaymentProcess(this.event.id, profileId, formId, this.selected.id)
      .subscribe({
        next: (result) => {
          this.gatewayDetails = result;

          this.gatewayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.waveGatewayService.getGatewayUrl(this.gatewayDetails));
          this.expiresIn = this.waveGatewayService.getExpiresInMin(this.gatewayDetails);


          this.intervalId = setInterval(() => {
            this.expiresIn = this.expiresIn - 1;
            if (this.expiresIn == 0) clearInterval(this.intervalId);
          }, 60000);

          this.isloading = false;
        },
        error: (error) => {
          console.log(error);
          this.isloading = false;
        }
      });
  }


  reset() {
    this.isloading = false;
    this.gatewayUrl = '';
    this.expiresIn = 0;
    this.gatewayDetails = {};
    if (this.intervalId) clearInterval(this.intervalId);
  }

  toggleModal() {
    this.isModal = !this.isModal;
    console.log(this.isModal);
  }
}
