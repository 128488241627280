import { Organisation } from "../organisation/organisation.model";
import { Profile } from "./profile.model";

class UploadedFile {
    public constructor(
        public name: string = ''
    ) { }
}

export class User {
    public constructor(
        public id: string = '',
        public ssoId: string = '',
        public firstName: string = '',
        public lastName: string = '',
        public phoneNumber: string = '',
        public email: string = '',
        public profilePhoto: UploadedFile = new UploadedFile(),
        public isEmailVerified: boolean = false,
        public isUsingDefaultToken: boolean = false,
        public roles: string[] = [],
        public createDate: string = '',
        public organisations: Organisation[] = [],
        public profiles: Profile[] = [],
        public about: string = '',
    ) { }
}
