import { CanActivateFn } from '@angular/router';

export const checkoutApproverGuard: CanActivateFn = (route, state) => {
  const profileId: string = route.paramMap.get('profileId');
  const formId: string = route.paramMap.get('formId');
  const eventId: string = route.paramMap.get('eventId');

  if (!formId || !profileId || !eventId) return false;

  return true;
};
