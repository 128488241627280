<div *ngIf="sponsors.length > 0"
  class="mx-auto mt-16 max-w-7xl px-6 lg:px-8 flex justify-center items-center gap-x-20 gap-y-28">
  @for (sponsor of sponsors; track sponsor._id) {
  <a [href]="sponsor.link" target="_blank" class="flex flex-1 flex-col p-8 text-center">
    <img class="mx-auto h-32 w-32 flex-shrink-0 rounded-full" [src]="sponsor.logo?.name | image | async"
      [alt]="sponsor.name">
    <h3 class="mt-6 text-sm font-medium text-gray-900">{{ sponsor.name }}</h3>
  </a>
  }
</div>
