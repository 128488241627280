import { Component } from '@angular/core';

@Component({
  selector: 'checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrl: './checkout-footer.component.scss'
})
export class CheckoutFooterComponent {

}
