import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subject, debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs';
import { ItemSelect } from '../../models/select-item.model';
import { CacheService } from './../../../services/cache.service';

@Component({
  selector: 'formly-country-input',
  templateUrl: './formly-country-input.component.html',
  styleUrls: ['./formly-country-input.component.scss']
})
export class FormlyCountryInputComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  showDropdown: boolean = false;
  selected: ItemSelect = { id: '', label: '', subElements: [] };
  options$;

  constructor(
    private CacheService: CacheService
  ){
    super();
  }

  ngOnInit() {
    if (this.CacheService.getCache('country')) {
      this.select(this.CacheService.getCache('country'))
    } else if (this.formControl.value && this.formControl.value.id) {
      this.select(this.formControl.value)
    }

    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(this.to.search$),
    );
    this.options$.subscribe();
  }

  search($event) {
    this.open();
    this.search$.emit($event.target.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

  open() {
    this.showDropdown = true;
  }

  close() {
    this.showDropdown = false;
  }

  select(option: ItemSelect) {
    if (this.selected.id == option.id) {
      this.selected = { id: '', label: '', subElements: [] };
      this.formControl.setValue('');
      this.CacheService.removeCache('country');
    } else {
      this.selected = option;
      this.formControl.setValue(option.id);
      this.CacheService.setCache('country', this.selected);
      this.close();
    }
  }

  isSelected() {
    return this.selected.id != '';
  }
}
