import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/models/event/event.model';
import { Sponsor } from 'src/app/shared/models/event/sponsor.model';
import { CheckoutApproverService } from 'src/app/shared/services/checkout-approver.service';

@Component({
  selector: 'checkout-sponsors',
  templateUrl: './checkout-sponsors.component.html',
  styleUrl: './checkout-sponsors.component.scss'
})
export class CheckoutSponsorsComponent implements OnInit {
  event: Event;
  sponsors: Sponsor[] = [];

  constructor(private checkoutService: CheckoutApproverService) { }

  ngOnInit(): void {
    this.event = this.checkoutService.event;
    this.sponsors = this.event.sponsors;
  }

}
