<header>
  <div>
    <div>
      <img class="h-36 w-full object-cover lg:h-52" [src]="event.theme.banner.name | image | async" alt="">
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="-mt-12 sm:-mt-14 sm:flex sm:items-end sm:space-x-5">
        <div class="flex">
          <img *ngIf="profile?.user?.profilePhoto?.name?.length > 0"
            class="h-24 w-24 rounded-full ring-4 ring-white bg-white sm:h-32 sm:w-32"
            [src]="profile?.user?.profilePhoto?.name | image | async" alt="">
          <img *ngIf="!profile?.user?.profilePhoto || profile?.user?.profilePhoto?.name == 0"
            class="h-24 w-24 rounded-lg ring-4 ring-white bg-white sm:h-32 sm:w-32"
            [src]="event?.theme?.logo?.name | image | async" alt="">
        </div>
        <div class="mt-8 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
          <div class="mt-8 min-w-0 flex-1 sm:hidden md:block">
            <h1 class="truncate text-2xl font-bold text-gray-900">{{ profile.salutation }} {{ profile.firstName }} {{
              profile.lastName }}</h1>
            <h3 class="mt-1.5 truncate text-lg font-bold text-gray-600">{{ profile.occupation }}</h3>
            <h5 *ngIf="profile.country?.name" class="mt-1.5 truncate text-base font-bold text-gray-400">{{ profile.country?.name }}</h5>
          </div>
        </div>
      </div>
      <div class="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
        <h1 class="truncate text-2xl font-bold text-gray-900">{{ profile.salutation }} {{ profile.firstName }} {{
          profile.lastName }}</h1>
      </div>
    </div>
  </div>
</header>
