import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { BuyTicketRequest } from '../models/event/buy-ticket-request.model';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class TicketingPurchaseService {

  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettingService,
    private errorService: ErrorService,
    private notification: NotificationService
  ) { }

  startPaymentProcess(eventId: string, profileId: string, formId: string, ticketId: number) {
    const request: BuyTicketRequest = {
      eventId: eventId,
      formId: formId,
      profileId: profileId,
      ticketId: ticketId
    };

    const url: string = `${this.appSettings.settings.url}/public/ticket-purchase`;
    return this.httpClient
      .post(url, request)
      .pipe(
        catchError(error => {
          return this.errorService.handleError(error, { title: 'Action failed', message: 'We could not load the payment gateway details' }, true);
        }),
      );
  }



}
