import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CheckoutFooterComponent } from './checkout-footer/checkout-footer.component';
import { CheckoutHeaderComponent } from './checkout-header/checkout-header.component';
import { CheckoutPricingSectionComponent } from './checkout-pricing-section/checkout-pricing-section.component';
import { CheckoutQuestionsSectionComponent } from './checkout-questions-section/checkout-questions-section.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutSponsorsComponent } from './checkout-sponsors/checkout-sponsors.component';
import { CheckoutComponent } from './checkout.component';
import { TicketsComponent } from './tickets/tickets.component';


@NgModule({
  declarations: [
    CheckoutComponent,
    TicketsComponent,
    CheckoutHeaderComponent,
    CheckoutPricingSectionComponent,
    CheckoutQuestionsSectionComponent,
    CheckoutFooterComponent,
    CheckoutSponsorsComponent
  ],
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    SharedModule
  ]
})
export class CheckoutModule { }
